import TopSection from './TopSection'
import Icons from './Icons'
import Register from './Register'
import Footer from './Footer'
import Web from './Web'
import Creator from './Creator'
import React, {useEffect} from "react";

const handleClickScroll = () => {
    const element = document.getElementById('formStart');
    if (element) {
        element.scrollIntoView({behavior: 'smooth'});
    }
}

const Home = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            handleClickScroll()
        }
    }, []);

    return (
        <div className="App">
            <TopSection/>
            <Icons/>
            <Web/>
            <Register/>
            {/*<Creator/>*/}
            <Footer/>
        </div>
    )
}

export default Home;