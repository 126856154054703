import React from 'react';
import trophy from '../assets/icon4.png'
import tactics from '../assets/icon3.png'
import arrow from '../assets/icon5.png'
import controller from '../assets/icon2.png'
import gem from '../assets/icon1.png'
import {useEffect, useRef, useState} from "react";

const data = {
    items: [
        {
            id: 1,
            img: arrow,
            title: 'User Focused',
            description: 'We monetize your content while keeping your end users in mind.'
        },
        {
            id: 2,
            img: trophy,
            title: 'High Revenue',
            description: 'Get paid for every user, using our ad-tech expertise you get up to 500% (on average) more than any competitor regarding CPM.'
        },
        {
            id: 3,
            img: controller,
            title: 'Gaming Centered',
            description: 'Created by gamers , for gamers , we put your audience needs in the highest importance'
        },
        {
            id: 4,
            img: gem,
            title: 'Get Compensated',
            description: 'You make good content that gets views? You should get paid for it, that’s where we come in.'
        },
        {
            id: 5,
            img: tactics,
            title: 'Data Oriented',
            description: 'View online real-time reports to analyse your users\' performance in our Panel.'
        }
    ]
}

const handleClickScroll = () => {
    const element = document.getElementById('formStart');
    if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({behavior: 'smooth'});
    }
}

const Icons = () => {
    const ref = useRef(0);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [componentOffsetTop, setComponentOffsetTop] = useState(0);

    useEffect(() => {
        function handleScroll() {
            const currentPosition = window.pageYOffset;
            setScrollPosition(currentPosition);
        }
        function handleResize() {
            const offsetTop = ref.current.offsetTop;
            setComponentOffsetTop(offsetTop);
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const componentPosition = componentOffsetTop - window.innerHeight / 1.2;
    const style = {
        opacity: scrollPosition > componentPosition ? 1 : 0,
        transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
    };
    return (
        <div ref={ref} style={style} className='Icons-Container'>
            <h1>Play, Share, Earn!</h1>
            <div className='Icons-Box'>
                {data.items.map(item => (
                    <div className="Icon-Item" key={item.id}>
                        <img src={item.img} alt={item.title}/>
                        <h3>{item.title}</h3>
                        <h5>{item.description}</h5>
                    </div>
                ))}
            </div>
            {/*<h1>And so much more...</h1>*/}
            {/*<button className='Style-Button' onClick={handleClickScroll}>Join Today &#10148;</button>*/}
        </div>
    );
};

export default Icons;
