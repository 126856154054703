import React, {useEffect, useState} from 'react';

const TopSection = () => {

    const handleClickScroll = () => {
        const element = document.getElementById('formStart');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({behavior: 'smooth'});
        }
    }
    const [value1, setValue1] = useState(0);
    const [value2, setValue2] = useState(0);

    useEffect(() => {
        const targetValue1 = 122;
        const increment1 = targetValue1 / 100;

        const timer1 = setInterval(() => {
            setValue1((prevValue) => {
                const newValue = prevValue + increment1;

                if (newValue >= targetValue1) {
                    clearInterval(timer1);
                    return targetValue1;
                }

                return newValue;
            });
        }, 10);

        const targetValue2 = 143037;
        const increment2 = targetValue2 / 100;

        const timer2 = setInterval(() => {
            setValue2((prevValue) => {
                const newValue = prevValue + increment2;

                if (newValue >= targetValue2) {
                    clearInterval(timer2);
                    return targetValue2;
                }

                return newValue;
            });
        }, 10);

        setTimeout(() => {
            clearInterval(timer2);
            setValue2(targetValue2);
        }, (targetValue1 / increment1) * 10);

        return () => {
            clearInterval(timer1);
            clearInterval(timer2);
        };
    }, []);

    return (
        <div  id='Top-BG' >
            <div className='Top-Banner'>
                <div className='Top-Text'>
                    <h1>You Create,<br/> We Monetize.</h1>
                    <h3>Join the gaming content revolution with LootLabs! <br/> We let content creators focus on doing what they do best, while we take care of the rest.</h3>
                    <div className='Top-Tiles-Div'>
                        {/*<div className='Top-Tiles'>*/}
                        {/*    <p>Creators <span className='Color-DotP'></span></p>*/}
                        {/*    <p style={{ fontSize: '35px', fontWeight: 900 }}>{value1.toFixed(0)}</p>*/}
                        {/*</div>*/}
                        {/*<div className='Top-Tiles'>*/}
                        {/*    <p>Money Earned <span className='Color-DotY'></span></p>*/}
                        {/*    <p style={{ fontSize: '35px', fontWeight: 900 }}>{value2.toFixed().toLocaleString()}$</p>*/}
                        {/*</div>*/}
                    </div>
                    <button className='Style-Button' onClick={handleClickScroll}>Sign Up</button>
                </div>
            </div>
        </div>
    );
};

export default TopSection;
