import React from 'react';

const Terms = () => {
    return (
        <div className="Text-Block">
            <>
                <blockquote>
                    <header>LOOTLABS TERMS OF SERVICE</header>

                    <p>Last Update: February 10, 2020</p>
                </blockquote>
                <p>
                    These Terms of Service and the Insertion Order (together, this “
                    <strong>Agreement</strong>”) are entered into as of the date (“
                    <strong>Effective Date</strong>”) of the applicable Insertion Order by and
                    between the Publisher (details of which are specified in the Insertion Order
                    which is an integral part hereof). Each Company and Publisher shall be
                    referred to as a “Party” and jointly as the “<strong>Parties</strong>”.
                </p>
                <p>
                    1. Definitions. Unless otherwise defined herein, capitalized terms shall
                    have the meanings ascribed to them below.
                </p>
                <p>1.1. &nbsp; “Ad”</p>
                <p>
                    any type of content (such as text messaging, audio and video files, rich
                    media, content, text, and graphic files), including but not limited to push
                    notifications, banners, buttons, pop-ups, pop-unders, lightbox interstitial
                    and text links that are generally used as an advertisements.
                </p>
                <p>1.2. &nbsp;“Advertiser”</p>
                <p>an entity who makes Ads available on the Platform.</p>
                <p>1.3 “Platform”</p>
                <p>
                    Company’s technology, allowing Publishers using Company’s services, to
                    present Ads on Publisher’s defined below Media (and other publishers media)
                </p>
                <p>1.4 .“Applicable Law”</p>
                <p>
                    applicable directives, laws, regulations and industry standards (“Laws”)
                    (including the Laws which apply to the End User (at its place of residence)
                    of a service or product of the Publisher and the Laws of the United States
                    of America and the European Union and its Member States)
                </p>
                <p>1.5. “End User”</p>
                <p>individual human end users of the Media.</p>
                <p>1.6.“Confidential Information”</p>
                <p>
                    The Parties agree that: (A) all information relating to the Solution or
                    access thereto, (B) the Platform or the Tag, (C) any information regarding
                    the content, purpose, design or function of the Solution, (D) any know-how,
                    technical data or other information, including, but not limited to, that
                    which relates to research, product plans, products, services, customers,
                    markets, developments, inventions, processes, marketing or finances that is
                    disclosed to Publisher; or (E) information concerning End Users interacting
                    with the Ads, shall be deemed as the Company’s Confidential Information (F)
                    Information concerning payouts and rates paid to Publisher.
                </p>
                <p>
                    The Parties understand, however, that Confidential Information shall not
                    include any information that either Party can establish by written record
                    (1) is already public knowledge without breach of confidentiality
                    restriction, or (2) was known to such Party prior to its negotiations with
                    the other Party, or (3) that is hereafter rightfully furnished to a Party by
                    a third party without restrictions on disclosure and without breach of
                    confidentiality restriction.
                </p>
                <p>1.7. “Tag”</p>
                <p>
                    Code that request an Ad from the Platform (which often can be referred to as
                    JS Tag, Ad Tag or an SDK in mobile applications), which is provided by
                    Company for implementation on Publisher Media
                </p>
                <p>1.8.“Inventory”</p>
                <p>
                    Internet assets in Publisher’s Media in which Ads are displayed, sold by
                    Publisher to Advertisers using the Platform.
                </p>
                <p>1.9. “Media”</p>
                <p>
                    Websites, applications, mobile websites, mobile applications, and other
                    media: (i) owned and controlled by Publisher, or (ii) that Publisher
                    otherwise has the contractual right to present and sell Ads on.
                </p>
                <p>1.10. “PP”</p>
                <p>The Privacy Policy available at the Platform.</p>
                <p>1.11. “Solution”</p>
                <p>
                    The Platform and its components including but not limited to the methods,
                    tools and other intellectual property of the Company.
                </p>
                <p>2. License</p>
                <p>
                    2.1. Subject to the terms provided herein the Company grants Publisher a
                    limited, non-exclusive, non-assignable and non-transferable right to place
                    the Tag on its Media and remotely access the Platform solely for the purpose
                    of presenting Ads on its Media through the Tag, during the Term (defined
                    below). Except for the limited license granted herein, all rights (including
                    intellectual property rights), title, interest and all copyright rights in
                    and to the Solution and part thereof are hereby reserved. Publisher hereby
                    grants to the Company during the Term, a limited, fully revocable,
                    non-exclusive, non-transferable license to integrate in Publisher’s
                    Properties the Solution and to copy, reproduce and distribute the look and
                    feel of the Publisher Properties in any way with solely in order to enable
                    the Actions in connection to the Company’s Solution by End Users in
                    accordance with the Restrictions and the terms herein.
                </p>
                <p>
                    2.2. Publisher shall not and shall not attempt to: (a) resell, sublicense,
                    lease, time-share, distribute or otherwise transfer to any third party its
                    right to access and use the Solution or any part thereof; (b) reverse
                    engineer, decompile or dissemble the Solution or any part thereof; (c)
                    modify, copy or create derivative works of the Solution or any part thereof,
                    or (d) access the Solution or any part thereof for the purpose of building a
                    competitive product or service or copying its features or user interface or
                    authorize or permit a direct competitor to do so. The Company reserves all
                    rights not expressly granted hereunder.
                </p>
                <p>
                    2.3. Publisher agrees that it is responsible for any acts or omissions of
                    its agents or permitted subcontractors that access or uses any of the
                    Solution or any part thereof, and Publisher will ensure that such agents and
                    permitted subcontractors comply with the terms of this Agreement.
                </p>
                <p>3. Publisher’s Representations and Warranties</p>
                <p>
                    3.1. Publisher hereby represents and warrants that: (a) it owns and controls
                    the Media; (b) it has the full corporate right, power and authority to enter
                    into this Agreement, to grant the licenses granted hereunder and to perform
                    the acts required of it hereunder; (c) when executed and delivered, this
                    Agreement will constitute the legal, valid and binding obligation of each
                    party, enforceable against each party in accordance with its terms; (d) it
                    has and will have all necessary rights and authority (1) to enter into this
                    Agreement and (2) to perform its obligations hereunder; (e) its performance
                    under this Agreement and duties hereunder, do not and will not breach any
                    agreement to which it is a party to or by which it is otherwise bound, or
                    any other obligation that it has with or to any of its advertisers,
                    publishers, networks or other third parties; (f) Publisher’s Media content,
                    services, products, materials, data, and information used by Publisher in
                    connection with this Agreement as well as the Solution do not and will not
                    (i) violate any Applicable Law, (ii) infringe, misappropriate or otherwise
                    breach the rights of any third party (including intellectual property
                    rights); (iii) contain any material which is unlawful, harmful, threatening,
                    defamatory, obscene, harassing, or racially, ethnically or otherwise
                    objectionable, which by way of example only, might mean that it contains:
                    (1) sexually explicit, pornographic or obscene content (whether in text or
                    graphics); (2) speech or images that are offensive, profane, hateful,
                    threatening, harmful, defamatory, libelous, harassing or discriminatory
                    (whether based on race, ethnicity, creed, religion, gender, sexual
                    orientation, physical disability or otherwise); (3) graphic violence; (4)
                    politically sensitive or controversial issues or (5) any unlawful behavior
                    or conduct; (g) it has entered (or will enter) into written agreements
                    granting Publisher all necessary licenses, rights and other similar consents
                    for the display of advertisements in the Media; (h) the Solution and the
                    Company’s activities pursuant to this Agreement (including the methods by
                    which and technology with which data is collected by Publisher and ads are
                    served to End Users) and documentation presented by Publisher to end users
                    to which the Ad Units are designated to &nbsp;have been reviewed by it, and
                    are compliant with all Applicable Laws and will not infringe any third party
                    rights; (i) it will comply with the Company’s Publisher Terms of Service and
                    Privacy Policy; and (j) it will not engage in any action or practice that
                    disparages or devalues the Company and/or the Solution, or the reputation or
                    goodwill of the Company.
                </p>
                <p>
                    3.2. Publisher shall implement the Tag in accordance with Company’s
                    instructions delivered to Publisher from time to time via the Platform or
                    any other manner.
                </p>
                <p>
                    3.3. Publisher will not modify the Tag or any Ad, and will only place the
                    Solution and its Ads in accordance with Applicable Law and reasonable
                    trafficking instructions which may be provided by the Company from time to
                    time. Publisher understands that any modification to the Ad Tags or failure
                    to comply with trafficking instructions may result in errors and
                    discrepancies for which the Company is not responsible.
                </p>
                <p>
                    3.4. Publisher will be solely responsible (a) for the Media (including the
                    content provided therein); (b) for all its use of the Solution hereunder
                    (including, without limitation, the use of Ads and their presentation in the
                    Media); (c) to use the Solution in compliance with Applicable Law and the
                    instructions provided by Advertisers and/or the Company; (d) to obtain, and
                    be deemed to have hereby granted to the Company, all rights necessary to
                    allow the Company to store, and serve the Ads or otherwise provide the
                    services hereunder (including the collection of information by the Company
                    according to the terms of the PP); (e) comply with all policies relating to
                    the Solution, including the Guidelines, attached as{" "}
                    <strong>Exhibit 3.4</strong>, as may be amended/provided from time to time.
                </p>
                <p>3.5. Publisher will not, nor will it attempt to:</p>
                <p>
                    3.5.1. copy, reproduce, modify, damage, disassemble, decompile, reverse
                    engineer or create derivative works of the Solution (including the Platform
                    or any portion thereof) or content provided there through, or any portion
                    thereof;
                </p>
                <p>
                    3.5.2. breach, disable, tamper with, develop or use (or attempt) any
                    workaround for, or otherwise damage Solution (including the Platform or any
                    portion thereof) or content provided there through, or any security measure
                    thereof;
                </p>
                <p>
                    3.5.3. interfere or attempt to interfere (whether through a device,
                    software, mechanism, routine or otherwise) with the proper working of
                    Solution (including the Platform or any portion thereof) or content provided
                    there through;
                </p>
                <p>
                    3.5.4. set, read, write, modify or delete any cookie on the other party or
                    any third party’s owned or operated domain;
                </p>
                <p>
                    3.5.5. transfer personally identifiable information to any other party, or
                    otherwise associate a cookie, web beacon, or other mechanism with personally
                    identifiable information, unless mutually agreed to in writing;
                </p>
                <p>
                    3.5.6. use the Solution, unless mutually agreed to in writing, to select or
                    target (a) sites directed at children under the age of 18 years, or (b)
                    based on information generally accepted as “sensitive” pursuant to Internet
                    advertising industry guidelines (e.g., the self-regulatory principles/code
                    of conduct of the Network Advertising Initiative) or Applicable Law, rule or
                    regulation (the Company may remove or deactivate any Media or Ads from the
                    Solution);
                </p>
                <p>
                    3.5.7 Make available any content or materials of abusive, pornographic,
                    vulgar, harassing, obscene, invasive or otherwise offensive, or, pursuant to
                    Applicable Law, illegal content of any kind in connection with the Solution
                    (including using the Solution); or
                </p>
                <p>
                    3.5.8. Alter or tamper with any information or materials on or associated
                    with any Service or the Platform or any content provided there through.
                </p>
                <p>3.6. Privacy</p>
                <p>
                    3.6.1. Publisher agrees: (i) to comply with and use the Solution in
                    accordance with all Applicable Laws; and (ii) to obtain End Users freely
                    given, specific, affirmative, informed, and unambiguous consent in
                    accordance with Applicable Law to the Company’s PP and Cookie Policy,
                    including with respect to placing or accessing any the Company’s cookies or
                    any other unique identifiers on the end Users’ device(s) and browser(s) the
                    collection of personal data and to the advertising or content being served;
                    (iii) to conspicuously display, maintain, and make readily accessible to End
                    Users, a privacy policy that complies with Applicable Law and provides
                    information on Customer’s data collection practices, including information
                    on the kind of data collected, the purposes for which the data will be
                    processed, any disclosures that will be made of the data and use of any
                    tracking mechanism, information on how to disable those tracking mechanisms;
                    (iv) that use of the Solution shall be governed by and subject to PP and
                    comply with Applicable Law; and (v) to provide End Users with the Company’s
                    opt-out for receiving Ads;
                </p>
                <p>
                    3.6.2. Publisher shall ensure that it does not associate the Platform with
                    any Personally Identifiable Information, or set, read, write, modify or
                    delete any cookies on the Company domain, or use cookies or tracking
                    technologies through the Platform in a manner that could collect or transmit
                    personally identifiable information unless prior to the first occurrence of
                    any such collection or transmission, Publisher: (a) first, fully,
                    accurately, clearly and conspicuously discloses the type of information
                    collected (described with specificity in the case of personally identifiable
                    information), the method of collection (e.g. by registration, etc.), and the
                    location of (i.e., a link to) the privacy policy that governs the
                    collection, use and disclosure of the information; and (b) then, obtains the
                    End User’s affirmative consent to such collection and/or transmission (prior
                    to such collection) including according to the PP and the Company’s Cookie
                    Policy.
                </p>
                <p>
                    3.7. Publisher represents and warrants it will not, either by itself or by
                    authorizing or encouraging others to do so, directly or indirectly, act in
                    violation of the foregoing terms.
                </p>
                <p>
                    4.1. The Company undertakes to pay Publisher within 30 days following the
                    end of any calendar month (Net+30) the Publisher Earnings. Publisher
                    Earnings means amounts actually received by the Company from Advertisers in
                    consideration for such Advertisers’ Ads displayed on the Publisher’s Media,
                    less associated system fees which include any operational costs (including,
                    but not limited to, virtualized computing, hosting, storage and bandwidth
                    charges), &nbsp;handling fees, Taxes (“<strong>System Fees</strong>”), and
                    adjusted as applicable to account for Reconciliation Adjustments. As used
                    herein, “<strong>Reconciliation Adjustments</strong>” means monthly
                    adjustments made as applicable in order to reconcile Publisher’s account.
                    Examples of Reconciliation Adjustments include but are not limited to: (a)
                    application of amounts previously withheld by or not collected from
                    Advertiser, (b) reporting and statistical errors, (c) deduction or roll-over
                    of amounts, (d) charge backs made by Advertiser with respect to Publisher’s
                    Media and (e) carrying over the uncollected balance of System Fees (without
                    derogating from any right or remedy of the Company).
                </p>
                <p>
                    4.2. All numbers with respect to the Ads for the purposes of billing and
                    payment shall be based on the Company’s reporting system. The Company will
                    provide the Publisher with automatic reports. The parties agree that
                    Company’s reports will be deemed as sole and decisive evidence with respect
                    to amounts owed hereunder and shall be binding upon the Publisher. The
                    Company shall have the right to place certain tracking technology in the Ads
                    (such as tracking pixel(s) or any other mechanism(s)) for observing,
                    recording, analyzing and optimizing the performance of the Ads and the
                    Media.
                </p>
                <p>
                    4.3. Publisher Earnings and corresponding System Fees shall be determined
                    based solely on numbers as reported in the Platform. Notwithstanding the
                    above, the Company reserves the right to not distribute Publisher Earnings:
                    (a) when amounts due to Publisher equal less than fifty dollars ($50) (in
                    which case amounts due will rollover to the next pay period), (b) if
                    Publisher fails to complete any tax or reporting forms reasonably requested
                    by the Company or to provide the Company with accurate tax information, or
                    (c) where Publisher Earnings are generated by any prohibited and/or
                    fraudulent activity.
                </p>
                <p>
                    4.4. Publisher acknowledges and agrees that Advertisers reserve the right to
                    refuse to pay on impressions, clicks or actions determined to be fraudulent,
                    artificially inflated or otherwise invalid, and that Publisher will not be
                    paid if the Company does not receive payment from the applicable Advertiser.
                </p>
                <p>
                    4.5. Without derogating from the above said (or from any right or remedy of
                    the Company), in case Company detects, in its sole discretion, fraudulent
                    activity: (i) Company may suspend and/or block Publisher’s account; and/or
                    (ii) no payment shall be paid for the entire day that such activity was
                    detected in; (iii) if Publisher believes that such activity is not
                    fraudulent activity, publisher must provide the company with sufficient
                    evidence as will be decided by Company.
                </p>
                <p>
                    4.6. Unless otherwise stated, all payments shall be made in U.S. dollars and
                    all amounts referenced herein refer to U.S. dollars.
                </p>
                <p>
                    4.7. All amounts payable hereunder are exclusive of any sales, use and other
                    taxes, tariffs duties or mandatory payments, however designated (“
                    <strong>Taxes</strong>”). Publisher is solely responsible for payment of any
                    Taxes applicable amounts paid hereunder..
                </p>
                <p>5. Term and Termination</p>
                <p>
                    5.1. This Agreement shall be in full force and effect as of the date of
                    registration of your account (in case self-service platform registration)
                    until terminated according to the terms provided herein.
                </p>
                <p>
                    5.2. The Company may suspend Publisher’s access to and use of its Platform
                    including its Solution and/or at its discretion terminate this Agreement if
                    the Company in its sole reasonable discretion believes that: (a) Publisher
                    is engaging in a prohibited activity, in particular if Publisher violates
                    any Applicable Law or (b) Publisher’s continued use of the Platform and or
                    Solution is likely to damage or cause imminent harm to the Platform, the
                    Solution, the Company or other users of the Solution and / or Platform (such
                    as Advertisers).
                </p>
                <p>
                    5.3. The Company may terminate this Agreement for convenience immediately
                    without any notice to Publisher.
                </p>
                <p>
                    5.4. Upon termination (i) the license provided by the Company hereunder
                    shall immediately terminate and expire; Publisher will immediately (a) cease
                    making any use of the Solutions and remove the Tag from the Media; (b) pay
                    all amounts owed to the Company hereunder; (iii) Sections&nbsp;‎6,‎7,‎8
                    and&nbsp;‎9 will survive the termination or expiration of this Agreement.
                </p>
                <p>6. CONFIDENTIAL INFORMATION</p>
                <p>
                    6.1. Each Party (“<strong>Receiving Party</strong>”) agrees that it will not
                    use or disclose any Confidential Information received from the other Party
                    (“<strong>Disclosing Party</strong>”) other than as expressly permitted
                    under this Agreement or as expressly authorized in writing by the disclosing
                    Party. The receiving Party shall use the same degree of care to protect
                    Confidential Information of the other Party as it uses to protect its own
                    confidential information of a similar nature, but in no event less than
                    reasonable care. Publisher agrees that it will require every employee or
                    consultant who will have access to, use of, or knowledge of the Solution to
                    execute (in advance of and as a condition to such access, use of or
                    knowledge) a confidentiality agreement including terms substantially similar
                    to those contained in this Section&nbsp;‎6.
                </p>
                <p>
                    6.2. Nothing in this Section&nbsp;‎6 will prevent either Party from
                    disclosing Confidential Information of the other to the extent required by
                    law, judicial order or other legal obligations, provided that in such an
                    event, the Party disclosing such Confidential Information of the other shall
                    notify such Party to allow such Party to seek a protective order or other
                    appropriate relief. If a protective order is not obtained, the Party
                    required to make such disclosure shall disclose only that portion of the
                    Confidential Information which its counsel, in its reasonable judgment,
                    advises is legally required to disclose.
                </p>
                <p>
                    6.3. Upon termination, expiration or earlier upon demand of the Disclosing
                    Party, the Receiving Party shall promptly, return to the Disclosing Party
                    all Confidential Information of such Party which is in tangible form, or
                    certify in writing that all such Confidential Information has been
                    destroyed.
                </p>
                <p>7. INDEMNIFICATION</p>
                <p>
                    7.1. Publisher shall indemnify, defend and hold harmless the Company its
                    affiliates directors, officers, agents, employees or successors from and
                    against any and all damages, liabilities, costs and expenses (including
                    reasonable attorneys’ fees) incurred by the Company arising out of any third
                    party claim related to any alleged or actual breach by Company of the
                    representations and warranties herein or Company’s access to or use of the
                    Solution (a “<strong>Claim</strong>”). The Company will notify the Publisher
                    of any Claim. Should the Company choose to tender the control and the
                    defense of any such Claim to the Publisher, it shall provide the Publisher
                    with information reasonably required (at the Publisher’s expense) in order
                    to defending such Claim. If the Company choose to tender the control and the
                    defense of any such Claim to the Publisher, the Company may join the
                    Publisher (at the Publisher’s expense) in the defense with counsel of its
                    choice. Any settlement requiring the Company to admit liability, pay money,
                    or take (or refrain from taking) any action, incur any liability, will
                    require the Company’s prior written consent.
                </p>
                <p>
                    7.2. If (1) a third party asserts a claim against Publisher asserting that
                    Publisher’s use of a Solution or any part thereof in accordance with this
                    Agreement violates that third-party’s patent, trade secret or copyright, or
                    (2) the Company believes the Solution, or any part thereof may violate a
                    right, then the Company will, at its expense: (a) modify such portion of the
                    Solution or any part thereof provided such modified deliverable has at least
                    equal functionality and features at the deliverable with which it is
                    replaced, or (b) procure the right to continue using the Solution or any
                    part thereof, and if (a) or (b) are not commercially reasonable, terminate
                    Publisher’s right to use the Solution. This section contains Publisher’s
                    exclusive remedy and the Company’s sole liability for any such claims.
                </p>
                <p>
                    7.3. THE SOLUTION AND SERVICES, IF ANY, ARE PROVIDED “AS IS”, “AS
                    AVAILABLE”, “WITH ALL FLAWS” AND WITHOUT ANY REPRESENTATIONS OR WARRANTIES,
                    EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF
                    MERCHANTABILITY, NONINFRINGEMENT, OR FITNESS FOR A PARTICULAR PURPOSE OR
                    USE, AND ALL SUCH WARRANTIES ARE HEREBY DISCLAIMED. THE COMPANY DO NOT
                    WARRANT THAT THE SOLUTION THE ADS OR SERVICES WILL OPERATE UNINTERRUPTED OR
                    ERROR-FREE, OR THAT ANY DEFECTS WILL BE CORRECTED. THE COMPANY SHALL NOT BE
                    RESPONSIBLE FOR SOFTWARE OR CONTENT THAT IS SERVED THROUGH OR LINKED FROM
                    THE SOLUTION, THE ADS OR SERVICES. THE PARTIES ACKNOWLEDGE THAT THE
                    FOREGOING DISCLAIMER IS A FUNDAMENTAL PART OF THIS AGREEMENT, AND THE
                    COMPANY WOULD NOT ENTER INTO THIS AGREEMENT ABSENT SUCH DISCLAIMER. The
                    Company shall not be liable for any loss (including but not limited to
                    direct loss of revenue, incurred cost or loss of profits) that may be
                    incurred by Publisher as a result of any failure of the Solution to operate
                    as expected or anticipated, including but not limited to a failure of any
                    operation of the Solution which results in an incorrect operation or failure
                    to operate as intended, any technical default in the Solution or incorrect
                    impression, campaign or publisher targeting.
                </p>
                <p>
                    7.4. The Company does not monitor content provided or made available in or
                    through Media or the Solution, nor does it screen or has any control over
                    such content. Publisher shall be solely responsible for all content so
                    provided and in no event shall the Company be liable for any damages or loss
                    whatsoever, whether direct, indirect, general, special, compensatory,
                    punitive, consequential, and/or incidental, arising out of or relating such
                    content or the use of the Media.
                </p>
                <p>8. Limitation of Liability</p>
                <p>
                    8.1. In no event shall either party be liable to the other party for any
                    incidental, indirect, special, exemplary, or consequential damages
                    including, but not limited to, damages for loss of profits, business
                    interruption, loss of information, and the like, in each case even if such
                    party has been advised of the possibility of such damages. Notwithstanding
                    anything to the contrary, Publisher’s total aggregate liability under this
                    Agreement, if any, to the Company’s or any other person or entity, in
                    connection with any claim relating to this Agreement, including any services
                    provided by or on behalf of company, will be limited to an amount equal to
                    the lower of (i) US$10,000 or (ii) the amount received by the Company under
                    this Agreement during the 30 day period immediately preceding the date of
                    the claim. The existence of one or more claims will not enlarge this limit.
                    This section shall survive the expiration and/or termination of this
                    Agreement.
                </p>
                <p>
                    8.2. No action arising under or relating to this Agreement, regardless of
                    its form, may be brought by either party more than six (6) months after the
                    cause of action has occurred and in any event no later than three (3) months
                    after the termination of this Agreement, except for an action for
                    non-payment brought by the Publisher. The foregoing limitations shall apply
                    notwithstanding any failure of essential purpose of any limited remedy and
                    are fundamental elements of the bargain between the parties.
                </p>
                <p>9. MISCELLANEOUS</p>
                <p>
                    9.1. Except for Publisher’s payment obligations under this Agreement,
                    neither party shall be liable by reason of any failure or delay in the
                    performance of its obligations hereunder for any cause beyond the reasonable
                    control of such party, including but not limited to electrical outages,
                    failure of Internet service providers, riots, insurrection, war (or
                    similar), fires, flood, earthquakes, explosions, and other acts of God.
                </p>
                <p>
                    9.2. This Agreement contains the entire agreement between the parties and
                    supersedes all other agreements between the parties. Without derogating from
                    the generality of the foregoing, in the event that the terms of this
                    Agreement are in conflict to the terms of any other agreement, provision,
                    quote, order, acknowledgment, or other communications between the parties,
                    the terms provided herein shall prevail over such conflicting terms (even if
                    the conflicting terms are incorporated in a written instrument signed by the
                    parties herein after the execution of this Agreement unless the Publisher
                    specifically referred in such instrument to the name and date of this
                    Agreement and to the amendment of its terms and conditions).
                </p>
                <p>
                    9.3. Publisher may not assign this agreement without the prior written
                    consent of the Publisher, and any assignments in violation of the foregoing
                    shall be void. Nothing in this Agreement is intended or will be construed to
                    give any person, other than the parties hereto, any legal or equitable
                    right, remedy or claim under or in respect of this Agreement or any other
                    provision contained herein. This Agreement shall be interpreted in
                    accordance with Israeli law and the courts in Tel Aviv shall have sole
                    jurisdiction over any disputes hereunder.
                </p>
                <p>
                    9.4. This Agreement may be executed in two counterparts, each of which shall
                    constitute an original and the two together shall constitute a single
                    agreement. Whenever possible, each provision of this Agreement shall be
                    interpreted in such a manner as to be effective and valid under applicable
                    law but, if any provision of this Agreement is held to be invalid, illegal
                    or unenforceable in any respect, such provision will be ineffective only to
                    the extent of such invalidity, or unenforceability, without invalidating the
                    remainder of this Agreement. The parties agree that Publisher may amend this
                    Agreement / provide Publisher with certain guidelines and that such
                    amendments/guidelines shall be binding upon the Publisher within 48 hours of
                    the date such amendments/guidelines has been sent to the Publisher.
                </p>
                <p>
                    9.5. Our services are designated to corporate entities and not natural
                    persons. If you are acting on behalf of a corporate, you hereby represent
                    and warrant that you are authorized to act on behalf of the corporate per
                    which you agree to these terms of services.
                </p>
                <h4>Exhibit&nbsp;‎&nbsp;3.4 Guidelines</h4>
                <p>
                    1. The Publisher may not use the Solution or the Ads in any application that
                    has been downloaded to user’s desktops without their permission.
                </p>
                <p>
                    2. The Publisher may not use Solution or the Ads through any software, which
                    does any of the following without End User’s explicit authorization: takes
                    control of the End User’s computer to send unsolicited information, diverts
                    or redirects End User’s browser, modifies computer settings for default
                    internet access providers, search providers, bookmarks, security settings or
                    web page display, renders security settings, security software or
                    anti-spyware programs inoperable, induces or deceives a user to provide
                    Personally Identifiable Information, cannot be uninstalled or removed by
                    standard and normal practices.
                </p>
                <p>
                    3. The Publisher may not use the Solution or the Ads in any Media that
                    contains content explicitly related to child pornography or is otherwise in
                    violation of Applicable Law.
                </p>
                <p>
                    4. If the Company believes that the Publisher is serving content that
                    improperly contains spyware or malware, the Company may at its sole
                    discretion, reply to third party inquiries and disclose Publisher name and
                    contact details to third party.
                </p>
                <p>
                    5. Any one of the preceding instances that occurs through Publisher’s Media
                    will count as a single violation of these Guidelines. Each instance will
                    result in a $1,000 non-refundable fine added to Publisher next monthly
                    invoice and the Company will reserve the right to take additional actions
                    available to it such as terminating the agreement or other available
                    remedies and actions according to Applicable Law.
                </p>
                <p>
                    6. The Parties agree to the following (without admitting any Personal Data
                    is collected).
                </p>
                <p>6.1. Definitions and Interpretation:</p>
                <p>
                    “Affiliate(s)” means in respect of either party at any time, any person or
                    legal entity controlled by or controlling or under the common control of
                    that party. Any reference to the Company shall include reference to its
                    Affiliates;
                </p>
                <p>“Data Subject” means the individual to whom the Personal Data relates;</p>
                <p>
                    “Data Protection Laws” means any Applicable Laws. It shall include ePrivacy
                    Directive 2002/58/EC as implemented by countries within the European
                    Economic Areas (“EEA”), (b) the General Data Protection Regulation (EU)
                    2016/679 (“GDPR”) and/or (c) other laws or subordinate legislation that
                    implement or amend the laws that are identified in (a) and (b) above from
                    time to time.
                </p>
                <p>
                    “End User Personal Data” means information about an End User which the
                    Company collects from End Users directly via the Ads, including the
                    Company’s own UUID, IP address and some other user agent data (for example,
                    information about which device and browser the End User is using to access
                    the Ads).
                </p>
                <p>
                    “Publisher Personal Data” means the personal data (such as the Publisher’s
                    employee’s name and email address) which the Publisher provides to the
                    Company in order to use the Company’s services (such as those required in
                    connection with Publisher’s account in the Platform).
                </p>
                <p>
                    “Personal Data” means any information about an identified and/or
                    identifiable natural person which the Company processes pursuant to the
                    Agreement and which may include the End User Personal Data and/or the
                    Publisher Personal Data (as applicable).
                </p>
                <p>
                    “Personal Data Breach” means a breach of security leading to the accidental
                    or unlawful destruction, loss, alteration, unauthorized disclosure of, or
                    access to, End User Persona Data or Publisher Personal Data transmitted,
                    stored or otherwise processed by the Company.
                </p>
                <p>
                    “Sub-processor” means sub-contractors and/or replacement sub-contractors (as
                    the case may be) which process personal data on behalf of the Company from
                    time to time.
                </p>
                <p>
                    The terms “Data Controller”, “Data Processor”, “Data Subject”, “Personal
                    Data”, and “Processing” shall have the same meaning ascribed to them under
                    the GDPR. 2.
                </p>
                <p>
                    6.2. Nature and Scope of Processing. The Publisher agrees to process
                    Personal Data pursuant to the purposes set forth in this Schedule and per
                    Applicable Laws. The Company shall process Personal Data for the following
                    purposes: (a) the End User Personal Data to provide online content to End
                    Users who interact with the Ads and improve the Company’s Services; and (b)
                    the Publisher Personal Data to provide the services to the Publisher
                    pursuant to the Agreement.
                </p>
                <p>
                    6.3. Role of Parties. The parties acknowledge and agree that they are each
                    independent controllers in respect of End User Personal Data and Publisher
                    Personal Data.
                </p>
                <p>
                    6.4. Each Party shall be individually and separately responsible for
                    complying with the obligations that apply to it as an independent Controller
                    under Applicable Laws.
                </p>
                <p>
                    6.5. Lawful Basis. (a) End User Personal Data. Publisher acknowledges that
                    the Company relies on Publisher’s lawful basis for processing End User
                    Personal Data as it does not have a direct relationship with an End User.
                    Accordingly, Publisher warrants that: (i) if Publisher relies on legitimate
                    interest, it has completed a legitimate interest assessment which has
                    considered the Company’s provision of the services; and (ii) the Publisher
                    shall (in any event) obtain the End User’s consent per the terms of the
                    Agreement. (b) Publisher Personal Data. Publisher acknowledges that the
                    Company’s lawful basis for processing Publisher Personal Data is
                    contractual, as the Publisher Personal Data is required in order for the
                    Company to perform its obligations under the Agreement.
                </p>
                <p>
                    6.6. Data Subject Rights. Publisher shall promptly inform the Company if it
                    receives a request from a Data Subject exercising its rights under the
                    Applicable Laws, to enable the Company to respond directly to such Data
                    Subject’s request. Publisher shall (if requested in writing and at the
                    requesting party’s sole cost and expense) provide reasonable assistance to
                    the Company, to fulfil the Data Subject’s request. The Publisher shall not
                    provide any information to any End User without first obtaining the
                    Company’s written consent.
                </p>
                <p>
                    6.7. Transparency. The Publisher acknowledges his respective transparency
                    obligations where Personal Data being processed is obtained directly from
                    the Data Subject and confirms that no Personal Data is obtained from a third
                    party. Publisher undertakes to display appropriate privacy notices to Data
                    Subjects (including as required by Applicable Laws, such as Art 13 of the
                    GDPR. The contact email for the Company for matters relating to Data Subject
                    rights is &nbsp;privacy@lootlabs.gg
                </p>
                <p>
                    6.8. Personnel. Publisher agrees that its personnel engaged in the
                    processing of Personal Data shall be informed of the confidential nature of
                    the Personal Data, receive appropriate training and have executed
                    confidentiality agreements. In addition, the Publisher shall restrict
                    personnel’s access to Personal Data to only those who require access to such
                    data in order to provide the services pursuant to the Agreement.
                </p>
                <p>
                    6.9. Sub-processors. Publisher may not use any Sub-processors without
                    obtaining Company’s prior consent. The Company may use sub-processors and
                    shall strive (i) to have each of its Sub-processors enter into a written
                    agreement subjecting such sub-processor to equivalent obligations with
                    respect to Personal Data as imposed under this DPA and Data Protection Laws;
                    and (ii) have the foregoing written agreement shall contain terms which
                    require the sub-processor to put in place appropriate technical and
                    organisational measures. Publisher acknowledges that the Company may engage
                    third parties (including its Affiliates) in connection with the provision of
                    the services pursuant to the Agreement.
                </p>
                <p>
                    6.10. Sharing Personal Data. In respect of the Personal Data, the Publisher
                    agrees that it shall not share personal data with the Company which (i)
                    except for Publisher Personal Data, allows Data Subjects to be directly
                    identified (for example, name and e-mail address) and/or (ii) contains any
                    Special Categories of Personal Data.
                </p>
                <p>
                    6.11. Data Security. Publisher shall maintain appropriate technical and
                    organisational measures for the protection of the security, confidentiality
                    and integrity of the Personal Data. The security measures the Company uses
                    to protect Personal Data shall be outlined in its Security Statement. If
                    either party becomes aware of a Personal Data Breach in respect of the
                    Personal Data it shall notify the other in writing as soon as reasonably
                    practicable, and take such steps as it deems necessary and reasonable in
                    order to remediate the cause of the Personal Data Breach.
                </p>
                <p>
                    6.12. Data Retention. The Company shall delete Publisher Personal Data when
                    requested by the Publisher. In respect of End User Personal Data, the
                    retention period shall be as provided in the Privacy Policy of the Company.
                </p>
                <p>
                    6.13. Compliance. The Publisher shall upon prior written request, make
                    available to the Company such information reasonably necessary to
                    demonstrate its compliance with the obligations under the Agreement. In
                    addition the Publisher shall notify the Company in writing (i) if, in its
                    opinion, an instruction from the Company infringes Applicable Laws; and (ii)
                    if a party receives a complaint, notice or allegation from any data
                    protection authority or similar body alleging non-compliance with Applicable
                    Laws in relation to the Agreement and/or services rendered thereunder.
                </p>
                <p>
                    6.13. Compliance. The Publisher shall upon prior written request, make
                    available to the Company such information reasonably necessary to
                    demonstrate its compliance with the obligations under the Agreement. In
                    addition the Publisher shall notify the Company in writing (i) if, in its
                    opinion, an instruction from the Company infringes Applicable Laws; and (ii)
                    if a party receives a complaint, notice or allegation from any data
                    protection authority or similar body alleging non-compliance with Applicable
                    Laws in relation to the Agreement and/or services rendered thereunder.
                </p>
                <p>
                    6.14. International Transfers. The Company may transfer or otherwise process
                    Personal Data outside of the EEA (including via a Sub-processor).
                </p>
            </>

        </div>
    );
};

export default Terms;
