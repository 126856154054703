import './App.css';
import Nav from './components/Nav'
import Home from './components/Home'
import Terms from './components/Terms'
import Privacy from './components/Privacy'
import Contact from './components/Contact'
import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";



function App() {

    return (
        <Router>
            <Nav/>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/contact' element={<Contact/>}></Route>
                <Route path='/privacy' element={<Privacy/>}></Route>
                <Route path='/terms' element={<Terms/>}></Route>
            </Routes>
        </Router>
    );
}

export default App;
