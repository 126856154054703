import React from 'react';
import {useEffect, useRef, useState} from "react";

const Web = () => {
    const ref = useRef(0);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [componentOffsetTop, setComponentOffsetTop] = useState(0);

    useEffect(() => {
        function handleScroll() {
            const currentPosition = window.pageYOffset;
            setScrollPosition(currentPosition);
        }
        function handleResize() {
            const offsetTop = ref.current.offsetTop;
            setComponentOffsetTop(offsetTop);
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const componentPosition = componentOffsetTop - window.innerHeight / 1.3;
    const style = {
        opacity: scrollPosition > componentPosition ? 1 : 0,
        transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
    };
    return (
        <div ref={ref} style={style} className='Web-Container'>
            <h1>Unleash Your Content Potential</h1>
            <h5>With hundreds of content creators using our solution, we have designed the best monetization tool for gaming oriented content. <br/>
                We allow creators to concentrate on creation and not worry about revenue.

            </h5>
            {/*<a href='/#formStart'>Browse the store <span className="text-button-icon">&#x2192;</span></a>*/}
        </div>
    );
};

export default Web;
