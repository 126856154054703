import React, {useState, useRef, useEffect} from 'react';
import ReCAPTCHA from 'react-google-recaptcha'
import Loader from "./Loader";
import {Tooltip as ReactTooltip} from "react-tooltip";
import './reg.css'
import HubspotContactForm from './Hub'


const Register = () => {
    const passRep = useRef(null);
    const passEr = useRef(null);
    const capEr = useRef(null);
    const failBlock = useRef(null);
    const successBlock = useRef(null);
    const check = useRef(null);
    const form = useRef(null);
    const [disabled, setDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const regToken = '001696ae-6e28-40e4-9851-a8f2c4719f83';
    const [data, setData] = useState({
        data: {
            username: '',
            first_name: '',
            signup_contact_type: '',
            signup_contact_id: '',
            signup_monetization_type: '',
            signup_monetization_site: '',
            bitcoin_allowed: 1,
            social_channel: '',
            last_name: '',
            password: '',
            captchaToken: '',
            read_terms: 1,
            user_optin: 1,
            mode: 0,
            operation: 'internal',
            source_id: 'lootlabs.gg',
            brand: "Loot Labs",
            n: 3,
            source_campaign: null,
            pub_type: null,
            rpid: null,
            refferer: document.referrer,
        }
    });

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('source_id')) {
            setData(prevState => {
                let val = urlParams.get('source_id')
                let data = Object.assign({}, prevState.data);
                data['source_id'] = val;
                return {data};
            })
        }
        if (urlParams.has('utm_source')) {
            setData(prevState => {
                let val = urlParams.get('utm_source')
                let data = Object.assign({}, prevState.data);
                data['source_id'] = val;
                return {data};
            })
        }
        if (urlParams.has('source_campaign')) {
            setData(prevState => {
                let val = urlParams.get('source_campaign')
                let data = Object.assign({}, prevState.data);
                data['source_campaign'] = val;
                return {data};
            })
        }
        if (urlParams.has('utm_campaign')) {
            setData(prevState => {
                let val = urlParams.get('utm_campaign')
                let data = Object.assign({}, prevState.data);
                data['source_campaign'] = val;
                return {data};
            })
        }
        if (urlParams.has('gclid')) {
            setData(prevState => {
                let val = urlParams.get('gclid')
                let data = Object.assign({}, prevState.data);
                data['gclid'] = val;
                return {data};
            })
        }
        if (urlParams.has('GCLID')) {
            setData(prevState => {
                let val = urlParams.get('GCLID')
                let data = Object.assign({}, prevState.data);
                data['gclid'] = val;
                return {data};
            })
        }
        if (urlParams.has('pub_type')) {
            setData(prevState => {
                let val = urlParams.get('pub_type')
                let data = Object.assign({}, prevState.data);
                data['pub_type'] = parseInt(val);
                return {data};
            })
        }
        if (urlParams.has('rpid')) {
            setData(prevState => {
                let val = urlParams.get('rpid')
                let data = Object.assign({}, prevState.data);
                data['rpid'] = parseInt(val);
                data['source_id'] = 'rpid_' + val
                return {data};
            })
        }
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        if (passRep.current.value !== data.data.password) {
            let inp = passRep.current
            let err = passEr.current
            inp.className = 'test'
            err.className = ''
            let err2 = capEr.current
            err2.className = 'HiddenEr'
            return
        }
        if (data.data.captchaToken === '') {
            let err = capEr.current
            err.className = ''
            let err2 = passEr.current
            err2.className = 'HiddenEr'
            return;
        } else {
            let err = capEr.current
            let err2 = passEr.current
            err.className = 'HiddenEr'
            err2.className = 'HiddenEr'
            register()
        }
    }

    const onCap = (val) => {
        setData(prevState => {
            let data = Object.assign({}, prevState.data);
            data['captchaToken'] = val;
            return {data};
        })
    }

    const handleForm = (e) => {
        e.preventDefault();
        let name = e.currentTarget.name
        let value = e.target.value
        setData(prevState => {
            let data = Object.assign({}, prevState.data);
            data[name] = value;
            return {data};
        })
    }

    const getPlaceholder = () => {
        if (data.data.signup_contact_type === 'Skype') {
            return 'https://join.skype.com/invite/UserId'
        } else if (data.data.signup_contact_type === 'Discord') {
            return '@Username'
        } else if (data.data.signup_contact_type === 'Telegram') {
            return '@Username'
        } else if (data.data.signup_contact_type === 'FaceBook') {
            return 'facebook.com/YourProfileUrl'
        } else if (data.data.signup_contact_type === 'Instagram') {
            return '@Username'
        } else if (data.data.signup_contact_type === 'Whatsapp') {
            return 'Ex: +123 123456789'
        } else {
            return 'Pick a messenger type'
        }
    }

    const getPlaceholderTraffic = () => {
        let traffic = data.data.main_source_of_traffic
        if (traffic === 'Skype') {
            return 'https://join.skype.com/invite/UserId'
        } else if (traffic === 'Discord') {
            return '@Username'
        } else if (traffic === 'Twitch') {
            return 'twitch.tv/YourProfileUrl'
        } else if (traffic === 'Telegram') {
            return '@Username'
        } else if (traffic === 'FaceBook') {
            return 'facebook.com/YourProfileUrl'
        } else if (traffic === 'Instagram') {
            return '@Username'
        } else if (traffic === 'Twitter') {
            return 'twitter.com/username'
        } else if (traffic === 'Kick') {
            return 'kick.com/username'
        } else if (traffic === 'Reddit') {
            return 'reddit.com/user/username'
        } else if (traffic === 'Whatsapp') {
            return 'Ex: +123 123456789'
        } else {
            return 'Enter your social channel URL'
        }
    }

    const register = async () => {
        delete data.data['password-rep']
        setIsLoading(true)
        const response = await fetch('https://api.lootlabs.gg/api/lootlabs/create_user', {
            method: "POST",
            body: JSON.stringify(data.data),
            headers: {"Content-Type": "application/json"},
            credentials: "same-origin"
        })
        const res = await response.json()
        let puid = res.message
        if (response.status === 200) {
            setIsLoading(false)
            let forms = form.current
            forms.innerText = ''
            let succ = successBlock.current
            succ.style.display = 'block'
            if (res.message.type === 'referer') {
                let refToken = res.message.new_token;
                let refTag = {
                    "name": "My Referral_" + res.message.referral_connected_publisher_id,
                    "serving_method_id": 16,
                    "site_id": parseInt(res.message.site_id),
                    "publisher_id": parseInt(res.message.publisher_id),
                    "ad_unit": 29,
                    "self_service": true,
                    "enable": true,
                    "ad_type_id": 9,
                    "ad_type": 9,
                    "operation_id": 57,
                    "activity": 1,
                    "production_flow_id": 0,
                    "referral_connected_publisher_id": parseInt(res.message.referral_connected_publisher_id)
                }

                const newLockerTagUrl = await fetch(`https://be.lootlabs.gg/api/publisher/${res.message.referral_connected_publisher_id}/site/${res.message.site_id}/placement?authenticationToken=${refToken}`, {
                    method: "POST",
                    body: JSON.stringify(refTag),
                    headers: {"Content-Type": "application/json"},
                    credentials: "same-origin"
                })
            }
            if (res.message.new_token) {
                let token = res.message.new_token;
                let reportCols = {
                    "columns": "cpm;impressions;sessions;subid",
                    "monetization_id": 51,
                    "show_100_percent_revenue": 0,
                    "impressions_shaving_percent": 100
                }
                const createFields = await fetch(`https://api.lootlabs.gg/api/publisher_report_columns?monetization_id=51&authenticationToken=${token}`, {
                    method: "POST",
                    body: JSON.stringify(reportCols),
                    headers: {"Content-Type": "application/json"},
                    credentials: "same-origin"
                })
            }

        } else {
            setIsLoading(false)
            let forms = form.current
            forms.innerText = ''
            let err = failBlock.current
            err.style.display = 'block'
            setTimeout(function () {
                window.location.reload();
            }, 150000);
        }
    }


    return (
        <div className="rights" id="formStart">
            {isLoading &&
                <Loader/>
            }
            {/*<form onSubmit={event => handleSubmit(event)} className="registration-form-publisher">*/}

            {/*<HubspotContactForm/>*/}
            {/*</form>*/}
            <form onSubmit={event => handleSubmit(event)} className="registration-form-publisher">
                <div className="succeed-block" style={{display: 'none'}} ref={successBlock}>
                    <p className="looking-title">We have successfully registered you to our platform.</p>
                    <p> We sent you an email to activate your account.</p>
                    <p> The login page can be found <b>
                        <u>
                            <a target='_blank' href="https://creators.lootlabs.gg/#/login">here</a>
                        </u>
                    </b>
                    </p>
                </div>
                <div className="fail-block" style={{display: 'none'}} ref={failBlock}>
                    <p className="looking-title">We have failed to register you, Please try again or contact our support
                        at <a style={{color: "#ae79ff"}} href='mailto:contact@lootlabs.gg'>contact@lootlabs.gg</a>.</p>
                </div>
                <div className="registration-form-wrapper" ref={form}>
                    <div className="looking-form-title">Sign up today to earn more from your links!
                    </div>
                    <p className="required-field">
                        <label>
                            <span>Full Name</span>
                            <br/>
                            <span className="wpcf7-form-control-wrap your-name">
              <input type="text" name="first_name" maxLength={40} minLength={2} onChange={event => handleForm(event)}
                     placeholder="Enter your full name" required="required"/>
            </span>
                        </label>
                    </p>
                    <p className="required-field">
                        <label>
                            <span>Email</span>
                            <br/>
                            <span>
              <input type="email" name="username" maxLength={40} onChange={event => handleForm(event)}

                     placeholder="Email (this will be used as your account username)" required="required"/>
            </span>
                        </label>
                    </p>
                    <p className="required-field password-input"
                       title="At least 8 Characters.                              Mixture of lower and upper case.                              Include numbers and symbols ($@#!?*%&)">
                        <label>
                            <span>Password</span>
                            <br/>
                            <span className="password-229">
              <input type="password" name="password" minLength={6} maxLength={40} onChange={event => handleForm(event)}
                     placeholder="Set your password (minimum 6 characters)" required="required"/>
              <i className="password-reveal fas fa-eye"/>
            </span>
                        </label>
                    </p>
                    <p className="required-field password-input">
                        <label>
                            <span>Repeat password</span>
                            <br/>
                            <span className="password-230">
              <input type="password" name="password-rep" maxLength={40} minLength={6} required="required" ref={passRep}
                     onChange={event => handleForm(event)}
                     placeholder="Type your password again"/>
              <i className="password-reveal fas fa-eye"/>
            </span>
                        </label>
                    </p>
                    <p className="required-field">
                        <label>
                            <span>Main Game</span>
                            <br/>
                            <span className="wpcf7-form-control-wrap your-name">
                                <select onChange={event => handleForm(event)} name="main_game" id="contactType"
                                        required="required">
                                    <option value="" disabled selected hidden>Pick one</option>
                                    <option value="Roblox"> Roblox</option>
                                    <option value="Minecraft"> Minecraft</option>
                                    <option value="Sims 4"> Sims 4</option>
                                    <option value="Grand Theft Auto V"> Grand Theft Auto V</option>
                                    <option value="Fortnite"> Fortnite</option>
                                    <option value="World of Warcraft"> World of Warcraft</option>
                                    <option value="Palworld"> Palworld</option>
                                    <option value="Valorant"> Valorant</option>
                                    <option value="Counter-Strike"> Counter-Strike</option>
                                    <option value="Dota 2"> Dota 2</option>
                                    <option value="Call of Duty: MW III"> Call of Duty: MW III</option>
                                    <option value="Escape from Tarkov"> Escape from Tarkov</option>
                                    <option value="Apex Legends"> Apex Legends</option>
                                    <option value="Dead by Deadlight"> Dead by Deadlight</option>
                                    <option value="Overwatch 2"> Overwatch 2</option>
                                    <option value="Rocket League"> Rocket League</option>
                                    <option value="Genshin Impact"> Genshin Impact</option>
                                    <option value="Honkai: Star Rail"> Honkai: Star Rail</option>
                                    <option value="Path of Exile"> Path of Exile</option>
                                    <option value="PUBG"> PUBG</option>
                                    <option value="Kerbal Space Program"> Kerbal Space Program</option>
                                    <option value="Other"> Other</option>


                                </select>
                            </span>
                        </label>
                    </p>
                    <p className="required-field">
                        <label>
                            <span>Main Source of Traffic</span>
                            <br/>
                            <span className="wpcf7-form-control-wrap your-name">
                                <select onChange={event => handleForm(event)} name="main_source_of_traffic"
                                        id="contactType" required="required">
                                    <option value="" disabled selected hidden>Pick one</option>
                                    <option value="Youtube">Youtube</option>
                                    <option value="Twitch">Twitch </option>
                                    <option value="Discord">Discord </option>
                                    <option value="Kick">Kick  </option>
                                    <option value="Reddit">Reddit  </option>
                                    <option value="Twitter">Twitter </option>
                                    <option value="Instagram">Instagram  </option>
                                    <option value="Facebook">Facebook </option>
                                    <option value="Website">Website </option>
                                    <option value="Other">Other </option>



                                </select>
                            </span>
                        </label>
                    </p>
                    <p className="required-field2">
                        <label>
                            <span>Social Channel Url (Optional)</span>
                            <br/>
                            <span className="wpcf7-form-control-wrap your-name">
                            <input type="text" name="social_channel" maxLength={80} minLength={2}
                                   onChange={event => handleForm(event)}
                                   placeholder={getPlaceholderTraffic()}/>
                            </span>
                        </label>
                    </p>
                    <div>
                        <p className="third-width">
                            <label>
                                <span>Messenger Type *</span><br/>
                                <span className="wpcf7-form-control-wrap">
                                        <select onChange={event => handleForm(event)} name="signup_contact_type"
                                                id="contactType" required="required">
                                            <option value="X">Pick one </option>
                                            <option value="Skype">Skype</option>
                                            <option value="Discord">Discord</option>
                                            <option value="Telegram">Telegram</option>
                                            <option value="FaceBook">Facebook</option>
                                            <option value="Instagram">Instagram</option>
                                            <option value="Whatsapp">WhatsApp</option>
                                        </select>
                                    </span>
                            </label>
                        </p>
                        <p className="tt-width">
                            <span className="wpcf7-form-control-wrap">
                                <label data-tooltip-id="my-tooltip-children-multiline">
                                    <input onChange={event => handleForm(event)} disabled="" type="text"
                                           name="signup_contact_id" size="40" className="" minLength="2" id="isDisabled"
                                           required="required" placeholder={getPlaceholder()}/>
                                </label>
                            </span>
                        </p>

                    </div>
                    <div className="bad-field-message blocked">The passwords do not match</div>
                    <p>
                        <label className="form-checkbox agree-block">
                            <br/>
                            <input onChange={event => setDisabled(!event.target.checked)} ref={check} type="checkbox"
                                   name="agree-01[]" defaultChecked required/>
                            <span className="checkmark"/>
                            <span className="agree-text">I agree to LootLabs's&nbsp;
                                <a target='_blank' rel="noreferrer"
                                   href="https://lootlabs.gg/terms/">Terms of Use</a> and to receive emails &amp; updates. <br/>I acknowledge that I have read LootLabs's <a
                                target='_blank' rel="noreferrer"
                                href="https://lootlabs.gg/privacy/">Privacy Policy.</a>
                            </span>
                        </label>
                    </p>
                    <div id="cp" style={{transformOrigin: "0 0"}}>
                        <ReCAPTCHA
                            sitekey="6LfGzJMlAAAAAIVkD-Xh8p7pkWJb3jIGKs80YPXx"
                            onChange={onCap}
                            theme='dark'
                        />
                    </div>
                    <p className="form-submit-block">
                        <button style={{width: '200px'}} type="submit" defaultValue="Sign up" disabled={disabled}
                                className="Style-Reg">Submit
                        </button>
                        <a target='_blank' href="https://creators.lootlabs.gg/">I'm already a member</a>
                    </p>

                    <span style={{color: 'red'}} className='HiddenEr' ref={passEr}>Passwords are not matching</span>
                    <span style={{color: 'red'}} className='HiddenEr' ref={capEr}>You must fill out the captcha</span>
                </div>
            </form>
            <ReactTooltip id="my-tooltip-children-multiline">
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span>Discord: Settings -> My Account -> "Username"</span>
                    <span>Telegram: Settings -> Account -> "Username"</span>
                    <span>Skype: Click Skype Profile -> Share Profile Button </span>
                    <span>Facebook: Go to your profile and copy the URL</span>
                    <span>Whatsapp: Add your country code and your phone number, for ex: +123 123456789 </span>
                </div>
            </ReactTooltip>
        </div>
    );
}

export default Register;
