import React from 'react';
import LogoBlack from '../assets/LogoNavW.png'

const Nav = () => {

    const handleClickScroll = () => {
        const element = document.getElementById('formStart');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({behavior: 'smooth'});
        } else {
            window.location.assign('/#formStart')
        }

    }

    return (
        <div className='Navbar'>
            <div className='Navbar-Left'>
                <a href='/'><img alt='' src={LogoBlack}/></a>
                {/*<a href="/terms">Terms of Use</a>*/}
                {/*<a href="/privacy">Privacy Policy</a>*/}
            </div>
            <div className='Navbar-Right'>
                <a id='Linker' href="/">Home</a>
                <a id='Linker' href="/blog">Blog</a>
                <a id='Linker' href="https://help.lootlabs.gg">Support</a>
                <a id='Linker' href="https://creators.lootlabs.gg/">Login</a>
                <a id='Reg' style={{cursor: 'pointer'}} onClick={handleClickScroll} >Sign Up</a>
            </div>
            </div>
    );
};

export default Nav;
