import React from 'react';

const Privacy = () => {
    return (
        <h1>Soon</h1>

    );
};

export default Privacy;
