import React from 'react';

const Contact = () => {
    return (
        <div className='Text-Block'>
            <header>Contact Us</header>
        </div>
    );
};

export default Contact;
